import React from 'react';

import Layout from '@components/layout';
import SEO from '@components/seo';
import ContactUsBanner from '@components/contactUsBanner';
import ContactSection from '@components/contactSection';

import '@styles/styles.scss';

export const ContactUs = ({ data }) => {
  const ctf = data.contentfulContactUs;
  const assets = data.allContentfulAsset.edges.map(edge => edge.node);

  const { title, subtitle, imageTitle } = ctf;

  return (
    <Layout>
      <SEO title="Contact Us"/>
      <ContactUsBanner
        title={title}
        subtitle={subtitle.subtitle}
        imageTitle={imageTitle}
        assets={assets}
      />
      <ContactSection
        bgClass="bg-white h-auto pt-5"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="w-100 pb-5 d-flex justify-content-center align-items-center">
              <h3>24/7 Phone Support: <a href="tel:0412228880">0412 228 880</a></h3>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUs;

export const pageQuery = graphql`
  query ContactUsQuery {
    contentfulContactUs {
      title
      subtitle {
        subtitle
      }
      imageTitle
    }
    allContentfulAsset {
      edges {
        node {
          file {
            contentType
            url
            fileName
          }
          title
        }
      }
    }
  }
`;
